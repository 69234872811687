.arkreen-main {
    padding:20px 20px
}

.arkreen-main-title {
    font-size:24px;
    line-height:32px;
    padding-block-end:10px;
    margin-top: -10px;
}

.ant-tag {
    margin-inline-end: 0px !important;
}

@media screen and (max-width: 576px) {
    .arkreen-404 {
        text-align: center;
        gap: 4px;
        padding: 16px 16px 16px 16px;
        margin-top: 20%;
    }
    .arkreen-404-info{
        font-weight: 400;
        font-size: 14px;
        text-align:center;
        padding: 8px;
    }
    .arkreen-select-group {
        display:flex;
    }
    .arkreen-select-item {
        width: 50%;
        float: left;
    }
    .arkreen-select-padding-left {
        padding-left: 6px;
    }
    .arkreen-select-padding-right {
        padding-right: 6px;
    }
}

@media screen and (min-width: 576px) {
    .arkreen-main-content {
        background-color:#FFFFFF;
        padding:16px 16px 16px 16px;
    }

    .arkreen-404 {
        width:100%;
        text-align: center;
        padding-top: 15%;
    }

    .arkreen-404-info{
        font-weight: 400;
        font-size: 14px;
        text-align:center;
        padding: 8px;
    }
    .arkreen-select-group {
        display:flex;
        padding-bottom: 6px;
    }
    .arkreen-select-item {
        float: left;
        padding-inline-end: 16px;
    }
}

.web3string {
    font-family: 'Roboto Mono', monospace;
}

.arkreen-404-title {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #101020;
    padding: 8px;
}

.arkreen-404-btn {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #00913A;
    padding: 8px;
}

.arkreen-404-back {
    color: #00913A;
    border: 1px solid #00913A;
    border-radius: 16px;
    align-items: center;
    padding: 5px 16px;
}

.arkreen-404-back:hover {
    color: #00913A !important;
    border: 1px solid #00913A !important;
    background-color: #E6F4EB;
}

.ant-layout .ant-layout-sider {
    transition:none !important;
}
